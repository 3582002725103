import React from "react";
import IconProcesses from "../../images/home-page/icons/clock-cog.png";
import IconLocallyOwned from "../../images/home-page/icons/shop.png";
import IconStopwatch from "../../images/home-page/icons/stop-watch.png";
import IconTag from "../../images/home-page/icons/tag.png";

const AboutOurProducts = () => {
  return (
    <div>
      <div className="container mx-auto my-6 lg:my-16 px-4 lg:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <div className="bg-grey-lighter mb-6 rounded-lg p-4 lg:p-8">
              <img src={IconStopwatch} alt="icon" width={80} className="mb-2" />
              <h2 className="text-lg text-grey-darkest font-bold mb-2">
                Fast & Efficient
              </h2>
              <p className="leading-loose text-grey-darker">
                The process of measuring and building custom basement window
                well covers can be lengthy if you don't have the process dialed
                in like we do. We're proud to be the most efficient company in{" "}
                {process.env.STATE_NAME} & {process.env.CITY_NAME_SECONDARY}{" "}
                because we have perfected our process to have your covers
                installed within 2-3 weeks. Don't be fooled by companies who
                claim to be quicker. Chances are that you'll get an inferior
                product or will end up waiting longer than expected. Check
                online reviews, do a little research and you'll see that{" "}
                {process.env.COMPANY_NAME} is the best.
              </p>
            </div>
            <div className="bg-grey-lighter mb-6 rounded-lg p-4 lg:p-8">
              <img src={IconProcesses} alt="icon" width={80} className="mb-2" />
              <h2 className="text-lg text-grey-darkest font-bold mb-2">
                Best Processes
              </h2>
              <p className="leading-loose text-grey-darker">
                15 years can teach you a lot of things. We've had plenty of time
                to perfect our manufacturing and installation processes. We
                stand behind our window well covers as the only company to offer
                a lifetime structural guarantee on both our steel window well
                covers and our polycarbonate window well covers. That's how you
                know you're getting quality basement window well covers from us.
                Sure, we've tried lots of different methods of coatings,
                welding, dipping, cutting, but we weren't satisfied until we
                figured out what truly held up in real life tests. We've learned
                more about structural rigidity than we need to know, but we're
                confident in our processes. No other company builds covers the
                way we do or uses our two-stage dipping process. We can ensure
                consistent repeatable results.
              </p>
            </div>
          </div>

          <div>
            <div className="bg-grey-lighter mb-6 rounded-lg p-4 lg:p-8">
              <img src={IconTag} alt="icon" width={80} className="mb-2" />
              <h2 className="text-lg text-grey-darkest font-bold mb-2">
                Best Value
              </h2>
              <p className="leading-loose text-grey-darker">
                You can find{" "}
                <a
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={process.env.PREFAB_SITE_URL}
                >
                  covers that cost less
                </a>{" "}
                and covers that cost more, however you simply will not find
                covers that are a better overall value than{" "}
                {process.env.COMPANY_NAME} {process.env.STATE_NAME} &{" "}
                {process.env.CITY_NAME_SECONDARY}. We have to take into
                consideration weight versus strength, ease of manufacturing
                versus aesthetics, and overall durability versus egress
                functionality. These are difficult trade-offs to analyze. We
                remove heavy, ugly, non-functional and compromised covers from
                homes on a daily basis. We laugh with customers as they tell us
                in exasperation, “What was I thinking when I got these!” In
                fact, it is a sad but true fact that our happiest customers are
                the customers who have tried something else first. The
                difference between our custom cover and anything else is that
                noticeable. Do yourself a favor and make the smart choice the
                first time around.
              </p>
            </div>
            <div className="bg-grey-lighter mb-6 rounded-lg p-4 lg:p-8">
              <img
                src={IconLocallyOwned}
                alt="icon"
                width={80}
                className="mb-2"
              />
              <h2 className="text-lg text-grey-darkest font-bold mb-2">
                Locally Owned
              </h2>
              <p className="leading-loose text-grey-darker">
                {process.env.COMPANY_NAME} is a locally owned company with roots
                in both {process.env.STATE_NAME} &{" "}
                {process.env.CITY_NAME_SECONDARY}. When you buy our covers, you
                support the entrepreneurial spirit that makes these communities
                such great places to live. {process.env.STATE_NAME} is here to
                stay.{" "}
                <a className="underline" href="/contact-us">
                  Let us know
                </a>{" "}
                what you need.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOurProducts;
